export enum Color {
  GREY_01 = "#161522",
  GREY_02 = "#28293D",
  GREY_03 = "#4B4D73",
  GREY_04 = "#8A8DBA",
  GREY_04_07 = "#8A8DBAB2",
  GREY_05 = "#C0C2DE",
  GREY_05_07 = "#C0C2DEB2",
  GREY_06 = "#E5E5F6",
  GREY_07 = "#EDEDF8",
  GREY_08 = "#F7F7FC",

  WHITE = "#FFFFFF",
  BACKGROUND = "#F7F7FE",

  LIGHT_05 = "#C7C7E5",

  SCALE_07 = "#DDDEDF",
  STATUS_ERROR_1 = "#F7F7FE",
  STATUS_ERROR_2 = "#FF8D8D",
  STATUS_ERROR_3 = "#FFC7C7",
  STATUS_ERROR_4 = "#FFF2F2",

  STATUS_WARNING_1 = "#FFC961",
  STATUS_WARNING_2 = "#FFD688",
  STATUS_WARNING_3 = "#FFE8BB",
  STATUS_WARNING_4 = "#FFF7E9",

  STATUS_SUCCESS_1 = "#39D98A",
  STATUS_SUCCESS_2 = "#86F1BC",
  STATUS_SUCCESS_3 = "#C8FFE4",
  STATUS_SUCCESS_4 = "#E9FFF4",
  STATUS_SUCCESS_5 = "#31C76D",
  STATUS_SUCCESS_DARK = "#2B4C4C",

  PRIMARY_BLUR = "#3C487A",
  PRIMARY_DARKER = "#2B3458",
  PRIMARY_DARK = "#3762C1",
  PRIMARY_DARK_07 = "#3762C1B2",
  PRIMARY_DEFAULT = "#3E7BFA",
  PRIMARY_50 = "#76A2FF",
  PRIMARY_150 = "#3568D4",
  PRYMARY_DISABLED = "#CBDAFD",
  PRYMARY_PLAIN = "#DBE5FD",
  PRYMARY_PALE = "#E0E9FE",
  PRYMARY_LIGHT = "#ECF2FF",
  PRYMARY_LIGHTER = "#F7FAFF",
  SCALE_04 = "#AFB3B8",
  SCALE_PARAGRAPH = "#707987",

  SECONDARY_DARKEST = "#3D2F41",
  SECONDARY_DARKER = "#E57162",
  SECONDARY_DEFAULT = "#FE7E6D",
  SECONDARY_50 = "#FFA69A",
  SECONDARY_DISABLED = "#FDD7CB",
  SECONDARY_LIGHT = "#FFF1EA",
  SECONDARY_LIGHTER = "#FFFAF7",

  LABEL_COUNTER = "#F15D5D",

  ERROR_DARKEST = "#7E3F4B",
  ERROR_DARKER = "#E85656",
  ERROR_DEFAULT = "#FF6161",
  ERROR_50 = "#F8EBEB",
  ERROR_DISABLED = "#DA5858",
  ERROR_DISABLED_07 = "#DA5858B2",
  ERROR_LIGHT = "#FFC7C7",
  ERROR_LIGHTER = "#FBEEEE",

  ACCENT_PURPLE_DEFAULT = "#775FFC",
  ACCENT_PURPLE_LIGHT = "#A89AFF",
  ACCENT_PURPLE_LIGHTER = "#E7E8FF",

  ACCENT_BLUE_DEFAULT = "#00DEE7",
  ACCENT_BLUE_LIGHT = "#A1FCFF",
  ACCENT_BLUE_LIGHTER = "#E5FEFF",

  ACCENT_GREEN_DEFAULT = "#70DF9E",
  ACCENT_GREEN_LIGHT = "#A5E7C0",
  ACCENT_GREEN_LIGHTER = "#ECFFF4",

  ACCENT_YELLOW_DEFAULT = "#FFCC77",
  ACCENT_YELLOW_LIGHT = "#FFDDA5",
  ACCENT_YELLOW_LIGHTER = "#FFFDEA",

  ACCENT_RED_DEFAULT = "#FE6470",
  ACCENT_RED_LIGHT = "#FFE0E2",

  ACCENT_ORANGE_DEFAULT = "#FE7E6D",
  ACCENT_ORANGE_LIGHT = "#FFA69A",
  ACCENT_ORANGE_LIGHTER = "#FFEFE7",

  ACCENT_SKY_LIGHT = "#E5FEFF",
  ACCENT_SKY_DEFAULT = "#00DEE7",
  TRANSPARENT = "transparent",

  CHART_GRID_PRIMARY = "#F8F8F8",

  CHART_PRIMARY_DARKER = "#152356",
  CHART_PRIMARY_DARK = "#172F89",
  CHART_PRIMARY_150 = "#314FBB",
  CHART_PRIMARY_DEFAULT = "#5B7DE8",
  CHART_PRIMARY_50 = "#8AA3FF",
  CHART_PRIMARY_LIGHT = "#C8D3FD",
  CHART_PRIMARY_LIGHTER = "#E6EBFF",

  CHART_LEGEND_DISABLED = "#CCCCCC",
}

interface GetThemeColorArgs {
  customColors: Record<string, string>;
  defaultColors: typeof Color;
}

export const getThemeColors = ({ customColors, defaultColors }: GetThemeColorArgs) => {
  const themeColors = Object.entries(defaultColors).map(([colorName, colorValue]) => {
    const formattedColorName = colorName.toLowerCase();

    if (!customColors[formattedColorName]) {
      return [colorName, colorValue];
    }

    return [colorName, customColors[formattedColorName]];
  });

  return Object.fromEntries(themeColors);
};
