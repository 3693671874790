import React from "react";
import { IconName } from "@technis/ui";
import { IconButton } from "@components/IconButton";
import { SearchBar } from "@components/SearchBar";

interface CommonSearchProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  iconName: IconName;
  title: string;
  searchValue: string;
  buttons?: React.ReactNode;
}

export const SearchWrapper = ({ onChange, children, iconName, title, searchValue, buttons }: CommonSearchProps) => (
  <div className="search-wrapper">
    <IconButton iconName={iconName} label={title} />
    <div className="search-bar-container">
      <SearchBar searchValue={searchValue} onChange={onChange} />
      {buttons}
    </div>
    {children}
  </div>
);
