import { ThemeType } from "@technis/shared";
import React from "react";
import BgTopLight from "./svg/login-top-right.svg";
import BgBottomLight from "./svg/login-bottom.svg";
import BgBottomLeftLight from "./svg/login-left-bottom.svg";
import BgCircleLight from "./svg/login-circle.svg";

const BACKGROUNDS = {
  [ThemeType.DARK]: [BgTopLight, BgBottomLight, BgBottomLeftLight, BgCircleLight],
  [ThemeType.LIGHT]: [BgTopLight, BgBottomLight, BgBottomLeftLight, BgCircleLight],
};

type BackgroundBubblesProps = {
  themeVariant: ThemeType;
  className: string;
};

export const BackgroundBubbles = ({ themeVariant, className }: BackgroundBubblesProps) => {
  const background = BACKGROUNDS[themeVariant].map((ImageComponent, index) => <img key={`login-bg-${index}`} src={ImageComponent} className={className} />);

  return <React.Fragment>{background}</React.Fragment>;
};
