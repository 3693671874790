import React from "react";
import { DefaultInput, IconCommon, IconName, Variant, Size } from "@technis/ui";
import { i18n } from "@lang/i18n";
import { translation } from "@lang/translation";

type SearchBarProps = {
  searchValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const SearchBar = ({ searchValue, onChange }: SearchBarProps) => (
  <DefaultInput
    className="search-input"
    colorVariant={Variant.PRIMARY}
    size={Size.LARGE}
    placeholder={i18n.t(translation.search.search)}
    icon={<IconCommon name={IconName.MAGNIFYING_GLASS} size={Size.LARGE} />}
    value={searchValue}
    onChange={onChange}
  />
);
