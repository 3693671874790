import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, NormalizedCacheObject, Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { AuthErrors } from "@technis/shared";
import { store } from "../store/store";
import { fetchGQL } from "./fetch";
import { saveToken } from "../redux/auth/auth.slice";
import { OperationDefinitionNode } from "graphql";
import { omitDeep } from "../utils/utils";

const getCurrentToken = () => store.getState().auth.token;
const getCurrentAuthorization = (token?: string) => `Bearer ${token || getCurrentToken()}`;
const setHeaderWithToken = (headers: Record<string, string>, token?: string) => ({
  headers: {
    ...headers,
    authorization: getCurrentAuthorization(token),
  },
});

const authLink = setContext((_, { headers }) => setHeaderWithToken(headers));

const autoRenewTokenState: { renewing: boolean; error?: string } = {
  renewing: false,
};

const renewTokenAndSave = () =>
  autoRenewTokenState.renewing
    ? new Promise<string | void>((resolve, reject) => {
        let i = 1;
        const interval = setInterval(() => {
          const clear = () => clearInterval(interval);
          if (i > 10) {
            clear();
            return reject("Token refresh timed out.");
          }
          if (!autoRenewTokenState.renewing) {
            if (autoRenewTokenState.error) {
              clear();
              return reject(autoRenewTokenState.error);
            }
            clear();
            return resolve();
          }
          i++;
        }, 500);
      })
    : Promise.resolve().then(() => {
        autoRenewTokenState.renewing = true;
        return fetchGQL<{ renew: string }>("query { renew }", getCurrentToken())
          .then((res) => {
            console.log("RENEWED TOKEN", res);
            const { renew: newToken } = res;
            if (!newToken) {
              throw new Error(AuthErrors.INVALID_TOKEN);
            }
            store.dispatch(saveToken(newToken));
            autoRenewTokenState.renewing = false;
            return newToken;
          })
          .catch((e: Error) => {
            autoRenewTokenState.renewing = false;
            autoRenewTokenState.error = e.message;
            throw e;
          });
      });

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const isTokenExpired = graphQLErrors.some(({ message }) => message === AuthErrors.TOKEN_EXPIRED);

    if (isTokenExpired) {
      const prevHeaders = operation.getContext().headers;
      return new Observable((observer) => {
        renewTokenAndSave()
          .then((newToken) => {
            operation.setContext(setHeaderWithToken(prevHeaders, newToken || getCurrentToken()));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          })
          .catch((err: Error) => {
            observer.error(err);
          });
      });
    }
  }
  if (networkError) {
    console.log(graphQLErrors, networkError, operation);
    console.log(`[Network error]: ${networkError}`);
    networkError = undefined;
  }
});

const httpLink = createHttpLink({
  uri: `${process.env.APPLICATION_API_URL}/graphql`,
});

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (((operation.query.definitions[0] || {}) as OperationDefinitionNode).operation === "mutation") {
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation);
});

const link = from([errorLink, authLink, omitTypenameLink, httpLink]);

export const api: { client: ApolloClient<NormalizedCacheObject> | undefined } = {
  client: undefined,
};

export const initApolloClient = async () => {
  if (!api.client) {
    api.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: true,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "cache-and-network",
          notifyOnNetworkStatusChange: true,
        },
      },
    });
  }
};
