import { AppColor, ComponentSize, Theme, ThemeContextProvider as UIThemeContextProvider, useTheme } from "@technis/ui";
import {
  BLACK,
  Genders,
  HEX_OPACITY,
  LIGHT_BLUE,
  LIGHT_PURPLE,
  LIGHTER_GRAY,
  MAGIC_BLUE,
  PINK,
  RetailMetrics,
  ThemeConfiguration,
  ThemeType,
  WHITE,
  DARK_BLUE,
} from "@technis/shared";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Color, getThemeColors } from "./color";

const ELECTRIC_VIOLET = "#4C00EE";
const PURPLE = "#8F4DEA";
const WHITE_LILAC = "#F5F7FB";
const DEEP_LILAC = "#7E57C2";
const DARK_VIOLET = "#363670";
const BLUE_CORNFLOWER = "#9bbeed";
const COLD_PURPLE = "#B39DDB";

const KpiColors = {
  [RetailMetrics.DWELL]: ELECTRIC_VIOLET,
  [RetailMetrics.CAPTURE_RATE]: LIGHT_BLUE,
  [RetailMetrics.POPULARITY]: PURPLE,
  [RetailMetrics.AFFLUENCE_IN]: MAGIC_BLUE,
  [RetailMetrics.AFFLUENCE_OUT]: "#5abfd7",
  [RetailMetrics.ABANDON_RATE_QUEUE]: "#f5a623",
  [RetailMetrics.AFFLUENCE_IN_QUEUE]: "#f5a623",
  [RetailMetrics.AFFLUENCE_OUT_QUEUE]: "#f5a623",
  [RetailMetrics.DWELL_QUEUE]: "#f5a623",
  [RetailMetrics.GROUP_SIZE_IN]: "#f5a623",
  [RetailMetrics.GROUP_SIZE_OUT]: "#f5a623",
  [RetailMetrics.INSIDE]: "#f5a623",
  [RetailMetrics.INSIDE_QUEUE]: "#f5a623",
  [RetailMetrics.N_GROUPS_IN]: "#f5a623",
  [RetailMetrics.N_GROUPS_OUT]: "#f5a623",
  [RetailMetrics.OCCUPANCY]: "#f5a623",
};

const GenderColors = {
  [Genders.MALE]: BLUE_CORNFLOWER,
  [Genders.FEMALE]: PINK,
  [Genders.UNKNOWN]: LIGHT_PURPLE,
  [Genders.ALL]: COLD_PURPLE,
};

export type AppTheme = Theme & {
  kpiColors: {
    [key in RetailMetrics]: string;
  };
  genderColors: {
    [key in Genders]: string;
  };
};

const getAppTheme = (themeConfiguration?: ThemeConfiguration) => {
  const highlightColor = themeConfiguration?.highlightColor || COLD_PURPLE;
  const highlightColor5 = highlightColor + HEX_OPACITY["5"];
  const highlightColor15 = highlightColor + HEX_OPACITY["15"];
  const highlightColor45 = highlightColor + HEX_OPACITY["45"];
  const lightTheme = {
    base: {
      colors: {
        primary: themeConfiguration?.primaryColor || DARK_VIOLET,
        secondary: themeConfiguration?.secondaryColor || DEEP_LILAC,
        background: themeConfiguration?.backgroundColor || WHITE_LILAC,
        light: WHITE,
        dark: BLACK,
        border: themeConfiguration?.primaryColor,
        hover: highlightColor5,
        shadow: highlightColor15,
      },
      font: {
        color: themeConfiguration?.fontColor || AppColor.OLD_GREY_40,
      },
    },
    components: {
      heading: {
        levels: {
          [ComponentSize.MEDIUM]: 20,
        },
      },
      input: {
        border: themeConfiguration?.primaryColor || AppColor.OLD_GREY_40,
      },
      topBar: {
        shadowColor: highlightColor15,
      },
      tabBar: {
        borderColor: highlightColor45,
        tabHoverColor: highlightColor15,
      },
      widget: {
        boxShadow: `0 0 1px 0 ${LIGHTER_GRAY}`,
      },
      table: {
        border: highlightColor15,
        headerBackgroundColor: highlightColor15,
        headerColor: themeConfiguration?.primaryColor || DARK_VIOLET,
        headerFontSize: 20,
        hover: highlightColor15,
        activeColor: themeConfiguration?.secondaryColor || DEEP_LILAC,
      },
      pieChart: {
        colors: [],
      },
      charts: {
        bar: {
          columnColor: themeConfiguration?.charts?.columnColors || [highlightColor],
          seriesColors: themeConfiguration?.charts?.seriesColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
          stackedColors: themeConfiguration?.charts?.stackedColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
        },
        fontColor: themeConfiguration?.primaryColor || DARK_VIOLET,
        gridMap: {
          titleColor: DARK_BLUE,
          plotBorderWidth: 0,
          borderColor: WHITE,
          borderWidth: 2,
          minColor: WHITE,
        },
        circle: {
          seriesColors: themeConfiguration?.charts?.seriesColors || ["#DCC8F9", "#C5A4F4", "#AE7FF0", PURPLE],
        },
      },
      actionItem: {
        hoverColor: highlightColor45,
      },
      dropDown: {
        backgroundColor: highlightColor45,
      },
      dropdownMenu: {
        backgroundColor: WHITE,
      },
      label: {
        color: AppColor.OLD_GREY_40,
      },
    },
    kpiColors: themeConfiguration?.kpiColors || KpiColors,
    genderColors: themeConfiguration?.genderColors || GenderColors,
    colors: getThemeColors({ defaultColors: Color, customColors: {} }),
  };

  const appTheme = {
    [ThemeType.LIGHT]: lightTheme,
    [ThemeType.DARK]: lightTheme,
    main: lightTheme,
  };

  return appTheme;
};

export const AppThemeContext = createContext({ theme: {} as AppTheme });

export const useAppTheme = (): { theme: AppTheme } => useContext(AppThemeContext);

type AppThemeProps = {
  children: JSX.Element;
  setRoot: boolean;
  setThemeVariant?: (variant: ThemeType) => void;
  theme: any;
};

const AppTheme = ({ children, setRoot, setThemeVariant, theme }: AppThemeProps) => {
  const [themeType, setThemeType] = useState(ThemeType.LIGHT);

  useEffect(() => {
    if (themeType && setRoot) {
      setThemeVariant && setThemeVariant(themeType);
      document.documentElement.className = themeType;
    }
  }, [themeType]);

  const currentTheme: AppTheme = theme[themeType];
  return <AppThemeContext.Provider value={{ theme: currentTheme }}>{children}</AppThemeContext.Provider>;
};

type ThemeContextProviderProps = {
  children: JSX.Element;
  setRoot: boolean;
  themeConfiguration?: ThemeConfiguration;
};

export const ThemeContextProvider = (props: ThemeContextProviderProps) => {
  const theme = getAppTheme(props.themeConfiguration);

  return (
    <UIThemeContextProvider defaultThemeVariant={ThemeType.LIGHT} theme={theme}>
      <AppTheme theme={theme} {...props} />
    </UIThemeContextProvider>
  );
};
