import { Messages } from "@technis/ui";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../redux/toast/toast.slice";
import { RootState } from "../store/rootReducer";

export const ToastHandler = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state: RootState) => state.toast.toasts) || [];

  const onClose = (toastId: string) => {
    dispatch(closeToast(toastId));
  };

  return <Messages messages={toasts} onClose={onClose} />;
};
