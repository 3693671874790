import React from "react";
import { useTheme, IconCommon, IconName, Size } from "@technis/ui";
import classNames from "classnames";

type IconButtonProps = {
  onClick?: () => void;
  iconName?: IconName;
  iconUrl?: string;
  label: string;
};

export const IconButton = ({ onClick, iconName, label, iconUrl }: IconButtonProps) => {
  const { theme } = useTheme();

  if (!iconName && !iconUrl) {
    return null;
  }

  return (
    <div className={classNames("icon-button", onClick && "icon-button-cursor")} onClick={onClick}>
      <IconCommon url={iconUrl} name={iconName} color={theme.colors.BASE_40} size={Size.EXTRA_LARGE_2} />
      <span style={{ color: theme.colors.BASE_40 }} className="icon-button-label">
        {label}
      </span>
    </div>
  );
};
