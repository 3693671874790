import { Logo, LOGO_TYPE } from "@technis/ui";
import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { i18n } from "../lang/i18n";
import { translation } from "../lang/translation";
import { AppTheme, useAppTheme } from "../common/Theme";

type TextSVGProps = {
  fill: string;
  text: string;
  width: string;
  height: string;
  align: "left" | "right" | "middle";
  fontSize: number;
};

const TextSVG: FunctionComponent<TextSVGProps> = ({ fill, text, width, height, align, fontSize }) => {
  let x;
  switch (align) {
    case "left":
      x = "40%";
      break;
    case "right":
      x = "60%";
      break;
    default:
      x = "50%";
  }
  return (
    <svg width={width} height={height} viewBox="0 -28 300 300" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <text fill={fill} fontSize={fontSize} textAnchor="middle" dominantBaseline="middle" x={x} y="50%">
        {text}
      </text>
    </svg>
  );
};

const renderErrorNumber = (theme: AppTheme, error: number) => {
  const chars = `${error}`.split("");
  const middleIndex = Math.floor(chars.length / 2);
  const isMiddleZero = chars[middleIndex] === "0";
  const fontSize = 250;

  let view;
  if (isMiddleZero) {
    view = chars.map((c, i) => {
      const key = `${c}-${i}`;
      const isMiddle = middleIndex === i;
      if (isMiddle && isMiddleZero) {
        return <Logo key={key} type={LOGO_TYPE.LOGO_ONLY} className="technis-logo" />;
      }
      let align: TextSVGProps["align"] = "middle";
      if (isMiddleZero) {
        align = i < middleIndex ? "right" : "left";
      }
      return <TextSVG key={key} fill={theme.base.font.color} fontSize={fontSize} text={c} width="30%" height="100%" align={align} />;
    });
  } else {
    view = <div className="normal-error-code">{error}</div>;
  }

  return <div className={classNames("error-number", { "has-technis-logo": isMiddleZero })}>{view}</div>;
};

type Props = {
  error: number;
  errorMessage?: string;
  className?: string;
  style?: React.CSSProperties;
  buttons?: React.ReactNode[];
};

export const PageErrorTemplate = ({ error = 404, errorMessage = translation.errors.noServer, className = "", style, buttons }: Props) => {
  const { theme } = useAppTheme();
  const errorNumber = renderErrorNumber(theme, error);

  return (
    <div className={classNames("error-template", className)} style={{ ...style, color: theme.base.font.color }}>
      {errorNumber}
      <div className="error-message">{i18n.t(errorMessage)}</div>
      {buttons ? <div className="buttons">{buttons.map((b) => b)}</div> : null}
    </div>
  );
};
