import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";

import { api, initApolloClient } from "./api/apollo";
import { persistor, store } from "./store/store";
import { AppRoutes } from "./routes/AppRoutes";
import { ErrorBoundary } from "./views/ErrorBoundary";

require("../src/styles/index.scss");

const firebaseConfig = {
  apiKey: process.env.FIREBASE_AUTH_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  storageBucket: process.env.GCLOUD_STORAGE_BUCKET,
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);

const App = () => {
  const [isLoadingApollo, setIsLoadingApollo] = useState(true);

  useEffect(() => {
    initApolloClient().finally(() => setIsLoadingApollo(false));
  }, []);

  return !isLoadingApollo && api.client ? (
    <CookiesProvider>
      <ApolloProvider client={api.client}>
        <Provider store={store}>
          <ErrorBoundary>
            <PersistGate loading={null} persistor={persistor}>
              <Helmet>{process.env.HUBSPOT_URL && <script type="text/javascript" id="hs-script-loader" async defer src={process.env.HUBSPOT_URL}></script>}</Helmet>
              <AppRoutes />
            </PersistGate>
          </ErrorBoundary>
        </Provider>
      </ApolloProvider>
    </CookiesProvider>
  ) : null;
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
